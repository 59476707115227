.sort-button {
    display: inline-block;
    height: 100%;
    padding: 0.5rem;
    white-space: nowrap;
    opacity: 0.75;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}
