/*** Semantic UI Button ***/

.ui.button {
    $btn-bg-color: #efeff0;
    $btn-shadow-color: #222426;

    box-shadow: 0 1px 2px 0 rgba($btn-shadow-color, 0.2);
    border-radius: 0;
    background-color: $btn-bg-color;
    color: rgba(#000, 0.6);
    transition: all 200ms ease;

    &:hover,
    &:focus {
        background-color: lighten($btn-bg-color, 2%);
        box-shadow: 1px 2px 6px 0 rgba($btn-shadow-color, 0.25);
        color: rgba(#000, 0.6);
    }

    &:active,
    &.active {
        background-color: lighten($btn-bg-color, 3%);
        box-shadow: 1px 4px 16px 0 rgba($btn-shadow-color, 0.2);
        color: rgba(#000, 0.6);
    }

    @media (max-width: var(--tablet-width)) {
        &:after {
            content: attr(data-label);
            margin-left: 0.42857143em;
        }
    }
}

/* .ui.button, .ui.icon.button, .ui.icon.buttons .button{
   padding-top: 0.65rem;
   padding-bottom: 0.65rem;
    margin: 0 0.5rem 0.5rem 0;
} */

button.ui.labeled.icon.basic.button {
    padding-left: 3.2rem !important;
    padding-right: 1rem !important;
}

.ui.fluid.left.labeled.icon.button,
.ui.fluid.left.labeled.icon.buttons .button {
    padding-left: 4.07142857em !important;
}
