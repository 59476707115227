:root {
    // container for full width layout
    --container-width: 1140px;

    // media-queries
    --tablet-width: 768px;
    --phone-width: 480px;

    // font
    --base-font: 'Lato', sans-serif;
    --heading-font: var(--base-font);
    --base-font-size: 18px;
    --base-font-weight: 300;
    --base-line-height: var(--base-font-size) * 1.5;

    // primary colors
    --primary: #042a4f; // hhc-blau
    --primary-light: lighten(var(--primary) 10%); // #064480
    --primary-dark: darken(var(--primary) 10%); // #02101e

    // extra colors
    --secondary: #1b1c1d;
    --accent: #761a2e; //hhc-accent
    --light-color: #ccc;
    --lighter-color: #e0e4e7;
    --dark-color: darken(var(--light-color), 20%); // #999
    --darker-color: darken(var(--dark-color), 20%); // #666

    // base colors
    --font-color: #414042;
    --background-color: #f2f2f2;

    // semantic colors
    --grey: #767676;
    --brown: #a5673f;
    --blue: #2185d0;
    --green: #21ba45;
    --orange: #f2711c;
    --pink: #e03997;
    --violet: #6435c9;
    --purple: #a333c8;
    --red: #db2828;
    --teal: #00b5ad;
    --olive: #b5cc18;
    --yellow: #fbbd08;
    --positive: $green;
    --negative: $red;
    --sui-default: #e8e8e8;
    --sui-light: #fcfcfc;

    // content specific colors
    --active-color: #80b380;
    --passive-color: #8080b3;

    // transitions
    --transition-bounce: cubic-bezier(0, 1.2, 0.6, 1.2);
    --transition-speedup: cubic-bezier(0, 0, 1, 0.5);
}
