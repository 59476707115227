[class^="filter-tag"] {
    $base-color: hsl(0, 0%, 45%);

    display: inline-block;
    padding: 0.25em 0.75em;
    background-color: $base-color;
    color: #fff;
    border-radius: 1em;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: lighten($base-color, 10%);
    }
}

.active-filter-tag-list {
    [class^="filter-tag"] {
        margin: 0.25em;
        padding-right: 0;

        .name {
            opacity: 0.5;
            margin-right: 0.5em;
        }

        .icon {
            margin: 0 0.5em;
            opacity: 0.5;
        }

        &:hover .icon {
            opacity: 1;
        }
    }
}

.filter-tag {
    $base-color: hsl(0, 40%, 45%);

    @mixin subClasses($list, $base-color, $colors: ()) {
        $angle: 360 / length($list);
        @each $item in $list {
            $i: index($list, $item);
            $bg-color: adjust-hue($base-color, $i * $angle);
            @if length($colors) >=$i {
                $bg-color: nth($colors, $i);
            }
            &-#{$item} {
                background-color: $bg-color;
                &:hover {
                    background-color: lighten($bg-color, 10%);
                }
            }
        }
    }

    &-status {
        $status-list: ("Applicant", "Trainee", "Active", "Alumni", "Out", "Passive");
        $colors: (adjust-hue($base-color, 330), adjust-hue($base-color, 140));
        @include subClasses($status-list, $base-color, $colors);
    }

    &-ressort {
        $ressort-list: ("IT", "MA", "HR", "SA", "FI", "QM", "NW", "none");
        @include subClasses($ressort-list, $base-color);
    }

    &-position {
        $position-list: (
            "Member",
            "HeadOf_NW",
            "HeadOf_QM",
            "HeadOf_FI",
            "HeadOf_IT",
            "HeadO_HR",
            "HeadOf_MA",
            "HeadOf_SA",
            "CFO",
            "COO",
            "CEO"
            "COO-2"
        );
        @include subClasses($position-list, $base-color);
    }
}
