/*** Blank Button ***/

.blank {
    &.ui.button,
    &.button {
        background-color: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        position: relative;
        z-index: 0;

        &:before {
            content: ' ';
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #eff0f0;
            z-index: -1;
            opacity: 1;
            transform-origin: center center;
            transform: scale(0);
            transition: all 0.2s var(--transition-bounce);
        }

        &.circular:before {
            border-radius: 50%;
        }

        &focus {
            &:before {
                opacity: 0.75;
                transform: scale(1);
            }
        }

        &hover {
            &:before {
                transform: scale(1);
            }
        }

        &active {
            &:before {
                opacity: 0.75;
                transform: scale(0.85);
            }
        }
    }
}
