.ui.container.page-container {
    max-width: 960px !important;
    width: 100%;
}

.pushable:not(body) {
    transform: none !important;
}

#main-content {
    margin-top: 5rem;
    margin-bottom: 2rem;

    @media (max-width: var(--phone-width)) {
        margin: 40px 0 0 !important;
    }
}

#main-navbar {
    position: fixed;
    width: 100%;
    z-index: 101;

    &.ui.menu {
        border-radius: 0;
        border: none;
    }
}

#mobile-sidebar {
    position: fixed;
    min-width: 50%;
    max-width: 100%;
    border: none;
    box-shadow: 2px 0 16px rgba(#000, 0.15), 8px 0 64px rgba(#000, 0.15);

    // sub menus
    .ui.dropdown {
        flex-wrap: wrap;

        & > .icon {
            display: none;
        }

        & > .menu {
            display: block;
            visibility: visible;
            position: relative;
            left: 0;
            border: 0;
            box-shadow: none;
            margin-top: 1rem;
            background-color: transparent;
        }

        .item:before {
            z-index: 12;
        }
    }

    .sm-hidden {
        display: none;
    }

    @media (max-width: var(--phone-width)) {
        min-width: 75%;
    }
}
