/**
 * MENU
 */

.ui.menu {
    &.scrollable {
        overflow-x: auto;

        // hack to compensate overflow-y beeing computed as auto
        // https://stackoverflow.com/a/39554003
        &.attached.tabular {
            padding-bottom: 1px;
            margin-bottom: -1px;
        }

        // hack to compensate overflow-y beeing computed as auto
        // https://stackoverflow.com/a/39554003
        &.pointing.secondary {
            padding-bottom: 2px;
        }

        &::-webkit-scrollbar {
            height: 4px;
        }

        .item {
            margin: 0;
        }
    }
}
