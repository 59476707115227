.signature-generator {
    &.ui.container {
        max-width: 720px !important;
        margin-bottom: 3rem;
    }

    iframe {
        border: none;
        padding: 0;
        margin: 0;
    }

    code {
        max-height: 9rem;
        background-color: #efefef;
        padding: 0.75rem;
        display: block;
        overflow: auto;
    }
}
