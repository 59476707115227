/******************************************
 ************    TYPOGRAPHY    ************
 ******************************************/

/* Text Sizes */
.text-mini {
    font-size: 0.65rem !important;
}
.text-tiny {
    font-size: 0.75rem !important;
}
.text-small {
    font-size: 0.85rem !important;
}
.text-medium {
    font-size: 1rem !important;
}
.text-large {
    font-size: 1.2rem !important;
}
.text-big {
    font-size: 1.35rem !important;
}
.text-huge {
    font-size: 1.5rem !important;
}
.text-massive {
    font-size: 1.75rem !important;
}
