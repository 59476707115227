/************    GENERAL    ************/

table {
    border-collapse: collapse;
}

a {
    cursor: pointer;
    user-select: none;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
