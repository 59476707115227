.react-datepicker-popper {
    z-index: 10 !important;
}

.react-datepicker {
    border: none !important;
    box-shadow: 1px 2px 6px 0 rgba(34, 36, 38, 0.25) !important;
    border-radius: 0 !important;
}

.react-datepicker__triangle {
    display: none !important;
}
.react-datepicker__header {
    background-color: #f4f4f5 !important;
}
