/*** Transparent Button ***/

.ui.button.transparent {
    box-shadow: none;
    background: transparent !important;
    border: none;
    color: rgba(#000, 0.4) !important;

    &hover {
        box-shadow: none;
        background: transparent !important;
        border: none;
    }
}
