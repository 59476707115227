/*******************************************
 ************    MAIN STYLES    ************
 *******************************************/

// variables amd helper
@import 'variables';
@import 'helper/helper';

// base
@import 'base/general';
@import 'base/typography';

// components
@import 'components/button/button';
@import 'components/inputs/text_input';
@import 'components/list';
@import 'components/date_picker';
@import 'components/menu';

/*** GENERAL ***/
.hover-pointer {
    cursor: pointer;
}

/*** Validation Message ***/
.validation-message {
    font-size: 0.85rem;
    margin-top: -0.25rem;
    display: block;
    line-height: 1;

    &.error {
        color: darkred;
    }

    &.wraning {
        color: darkgoldenrod;
    }
}

/****************************************************
 ************    SEMANTIC UI OVERRIDE    ************
 ****************************************************/

/*** GENERAL ***/
body {
    background-color: #f2f2f2;
    height: auto;
    min-height: 100vh;
}

#root,
#root > .pushable,
#root > .pushable > .pusher {
    min-height: 100vh;
}

table {
    width: 100%;
}

td,
th {
    padding: 0.25rem 0.5rem;
    vertical-align: top;
    text-align: left;
}

h1,
h2 {
    color: var(--primary) !important;
    font-weight: 300;
    text-align: center;
}

h1:first-child {
    margin-top: 2rem;
}

a:hover {
    color: inherit;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}
/* Container */
.ui.container {
    &.small {
        max-width: 480px !important;

        @media (min-width: calc(480px + 2 * 1rem)) {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}

.ui.header {
    font-weight: 300;
}

.ui.segment,
.ui.segments {
    border-radius: 0;
    border: none;
}

.ui.segments:not(.horizontal) > .segment {
    &:first-child,
    &:last-child,
    &:only-child {
        border-radius: 0;
    }
}

.ui.selection.dropdown {
    min-width: 8em;
}

.ui.multiple.dropdown {
    padding: 0.5rem;
}

.ui.dropdown .dropdown.icon {
    background-color: transparent !important;
}

.popup .ui.centered.inline.loader.active,
.popup .ui.centered.inline.loader.visible {
    margin: 0 auto 2rem;
}

/*** FORM FIELDS ***/
.ui.form .fields .field:first-child,
.ui.form :not(.fields) > .field,
.ui.form :not(.fields) > .wide.field {
    padding-left: 0;
}
.ui.form .field > label {
    margin: 0.5rem 0 0;
    font-weight: normal;
    opacity: 0.75;
}
.ui.form .fields > .field > label {
    margin-top: 0;
}
.ui.form .field > .fields {
    margin: 0;
}
.ui.form .field,
.ui.form .fields .field,
.ui.form .fields:last-child .field {
    margin-bottom: 0.5rem;
}
.ui.form .fields > .field,
.ui.form .fields:last-child > .field,
.ui.form .fields .field:last-child {
    margin-bottom: 0;
}

/*** FORM INPUTS ***/
.ui.form input:not([type]),
.ui.form input[type='text'],
.ui.form input[type='email'],
.ui.form input[type='search'],
.ui.form input[type='password'],
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='tel'],
.ui.form input[type='time'],
.ui.form input[type='url'],
.ui.form input[type='number'],
.ui.form select,
.ui.form .dropdown,
.ui.form textarea {
    background-color: #fcfcfc;
}

.ui.tiny.image {
    width: 64px;
}

.ui.form .fields {
    margin: 0 0 1em;
}

/* Dropdown Errors */
.ui.form .field.error .dropdown {
    background-color: #fff6f6;
}

.ui.form .field.error .ui.dropdown .menu,
.ui.form .fields.error .field .ui.dropdown .menu {
    .item:hover .text,
    .item.selected .text {
        background-color: #fbe7e7;
    }

    .item.active .text {
        background-color: #fdcfcf;
    }
}

/*** SEARCH ***/
.ui.search > .results .result.active .title {
    color: #000;
    color: rgba(0, 0, 0, 1);
}
.ui.search > .results .result.active .description {
    color: #666;
    color: rgba(0, 0, 0, 0.6);
}

/*** DIVIDER ***/
.ui.relaxed.list .ui.fitted.divider + .list {
    padding: 0.5em 0 0.75em 0.5em;
}
.ui.divider.massive {
    margin: 2rem 0;
}
.ui.divider.huge {
    margin: 1.5rem 0;
}
.ui.divider.big {
    margin: 1.25rem 0;
}
.ui.divider.normal {
    margin: 1rem 0;
}
.ui.divider.small {
    margin: 0.5rem 0;
}
.ui.divider.tiny {
    margin: 0.25rem 0;
}
.ui.divider.mini {
    margin: 0.15rem 0;
}

.ui.stackable.grid {
    width: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/*** CUSTOM GRID SIZE ***/
.ui.grid.tight {
    margin: -0.5rem !important;
}
.ui.grid.tight > .stackable.stackable.row > .column,
.ui.stackable.grid.tight > .column.grid.tight > .column,
.ui.stackable.grid.tight > .column.row > .column,
.ui.stackable.grid.tight > .column:not(.row),
.ui.stackable.grid.tight > .row > .column,
.ui.stackable.grid.tight > .row > .wide.column,
.ui.stackable.grid.tight > .wide.column {
    padding: 0.5rem !important;
}

/*** MODALS ***/
.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.65);

    &.transparent {
        background-color: transparent;
    }
}

.ui.modal .content {
    background-color: #efefef;
}

.ui.modal > .content {
    > .icon + .description,
    > .image + .description {
        width: 100%;
    }
}

.ui.modal > .icon:first-child + *,
.ui.modal > :first-child:not(.icon) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ui.modal > :last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/*** ICONS ***/
i.icon {
    line-height: 1;
}

/*** GENERAL MEDIA QUERIES ***/
@media only screen and (max-width: 767px) {
    .ui .field {
        padding: 0 !important;
    }
    .ui.form .fields .field,
    .ui.form .fields:last-child .field {
        margin-bottom: 0.5rem 1rem !important;
    }

    .ui.grid .ui.stackable.grid,
    .ui.segment:not(.vertical) .ui.stackable.page.grid {
        margin-left: -0.5rem !important;
        margin-right: -0.5rem !important;
    }
}
