// Clearfix
.clearfix {
    content: '';
    display: block;
    clear: both;
}

// Collapsable
.collapsable-trigger {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:after {
        content: '\f106'; // fa-angle-down
        font-family: 'Icons';
        line-height: 1;
        margin-left: auto;
        font-weight: 400;
    }

    &.collapsed:after {
        content: '\f107'; // fa-angle-up
    }
}

.collapsable-target {
    &.collapsed {
        display: none;
    }
}

// Margin and Padding Helper
// a = all sides
// x / y = left and right / top and bottom
// t / b / l / r = top / bottom / left / right

$margins: 0.125rem, 0.25rem, 0.5rem, 1rem, 1.5rem, 2rem, 2.5rem, 3.5rem, 5rem;
$paddings: $margins;
@for $i from 1 through 9 {
    // Margin
    .ma-#{$i} {
        margin: nth($margins, $i) !important;
    }
    .mt-#{$i} {
        margin-top: nth($margins, $i) !important;
    }
    .mb-#{$i} {
        margin-bottom: nth($margins, $i) !important;
    }
    .ml-#{$i} {
        margin-left: nth($margins, $i) !important;
    }
    .mr-#{$i} {
        margin-right: nth($margins, $i) !important;
    }
    .mx-#{$i} {
        margin-left: nth($margins, $i) !important;
        margin-right: nth($margins, $i) !important;
    }
    .my-#{$i} {
        margin-top: nth($margins, $i) !important;
        margin-bottom: nth($margins, $i) !important;
    }

    // Padding
    .pa-#{$i} {
        margin: nth($paddings, $i) !important;
    }
    .pt-#{$i} {
        margin-top: nth($paddings, $i) !important;
    }
    .pb-#{$i} {
        margin-bottom: nth($paddings, $i) !important;
    }
    .pl-#{$i} {
        margin-left: nth($paddings, $i) !important;
    }
    .pr-#{$i} {
        margin-right: nth($paddings, $i) !important;
    }
    .px-#{$i} {
        margin-left: nth($paddings, $i) !important;
        margin-right: nth($paddings, $i) !important;
    }
    .py-#{$i} {
        margin-top: nth($paddings, $i) !important;
        margin-bottom: nth($paddings, $i) !important;
    }
}

// Display
.d {
    &-block {
        display: block !important;
    }
    &-flex {
        display: flex !important;
    }
    &-inline {
        display: inline !important;
    }
    &-inline-flex {
        display: inline-flex !important;
    }
    &-grid {
        display: grid !important;
    }
    &-none {
        display: none !important;
    }
}

// Flexbox
.align {
    &-center {
        align-items: center !important;
    }
    &-end {
        align-items: flex-end !important;
    }
    &-start {
        align-items: flex-start !important;
    }
    &-stretch {
        align-items: stretch !important;
    }
    &-baseline {
        align-items: baseline !important;
    }
    &-baseline {
        align-items: baseline !important;
    }
}

.justify {
    &-center {
        align-items: center !important;
    }
    &-end {
        align-items: flex-end !important;
    }
    &-start {
        align-items: flex-start !important;
    }
    &-space-between {
        align-items: space-between !important;
    }
    &-space-around {
        align-items: space-around !important;
    }
}

.flex {
    &-wrap {
        flex-wrap: wrap !important;
    }
    &-column {
        flex-direction: column !important;
    }
    &-row {
        flex-direction: column !important;
    }

    @for $i from 0 through 3 {
        &-grow-#{$i} {
            flex-grow: $i !important;
        }
        &-shrink-#{$i} {
            flex-shrink: $i !important;
        }
    }
}

// Typography
.text {
    &-left {
        text-align: left !important;
    }
    &-right {
        text-align: left !important;
    }
    &-center {
        text-align: center !important;
    }
}
