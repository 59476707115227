.member-list-entry {
    .image-cell-wrapper {
        display: flex;
        align-items: center;
    }

    .profile-picture {
        width: 42px;
        height: auto;
        border-radius: 50%;
    }

    td {
        vertical-align: middle;
    }
}
