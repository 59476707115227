@mixin top-rows {
    & > tbody > tr {
        @content;
    }
}

.list-container {
    overflow: auto;
}

.list {
    border-collapse: collapse;

    th {
        border-bottom: 1px solid var(--light-color);
        box-sizing: content-box;
    }

    @include top-rows {
        td {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            border: 0px solid var(--light-color);
        }
    }

    &.divided {
        @include top-rows {
            &:not(:last-child) td {
                border-bottom-width: 1px;
            }
        }
    }

    &.striped {
        @include top-rows {
            &:nth-child(even) {
                background-color: rgba(var(--light-color), 0.3);
            }
        }
    }

    &.dashed {
        @include top-rows {
            td {
                border-style: dashed !important;
            }
        }
    }

    &.hoverable {
        @include top-rows {
            &:hover {
                background-color: rgba(var(--light-color), 0.5) !important;
            }
        }
    }
}
